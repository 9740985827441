// include client window logic in browser
const { EVENTS } = require("./src/client/config")

// scroll lock
const { clearQueueScrollLocks, enablePageScroll } = require("scroll-lock")

// utility
const triggerEvent = require("./src/core/utility/trigger-event").default
const callWhenIdle = require("./src/core/utility/call-when-idle").default
const getCrossBrowserWindowValues = require("./src/core/utility/get-cross-browser-window-values")
  .default

/**
 * Trigger gatsby event on window
 */
exports.onClientEntry = () => {
  triggerEvent(EVENTS.CLIENT_ENTRY)

  // use correct values for --fvw and --fvh
  const setCssSizeVariables = () => {
    const { size } = getCrossBrowserWindowValues()
    const { width, height } = size

    document.documentElement.style.setProperty("--fvw", `${width}px`)
    document.documentElement.style.setProperty("--fvh", `${height}px`)
  }

  window.addEventListener("resize", () => callWhenIdle(setCssSizeVariables))
  setCssSizeVariables()
}

/**
 * Trigger gatsby event on window
 * Track any async loading
 */
exports.onInitialClientRender = () => {
  triggerEvent(EVENTS.INITIAL_CLIENT_RENDER)

  // hook into skele data load events
  let trackIds = []
  window.addEventListener(EVENTS.ASYNC_LOAD_START, ({ detail }) => {
    trackIds.push(detail.id)
  })
  window.addEventListener(EVENTS.ASYNC_LOAD_END, ({ detail }) => {
    trackIds = trackIds.filter(id => id !== detail.id)

    if (!trackIds.length) {
      triggerEvent(EVENTS.ASYNC_LOAD_END_ALL)
    }
    callWhenIdle(() => triggerEvent(EVENTS.FORCE_REDETECT))
  })
}

/**
 * Trigger gatsby event on window
 */
exports.onPostPrefetchPathname = () => {
  triggerEvent(EVENTS.POST_PREFETCH_PATHNAME)
}

/**
 * Trigger gatsby event on window
 */
exports.onPreRouteUpdate = ({ location, prevLocation }) => {
  triggerEvent(EVENTS.PRE_ROUTE_UPDATE, { location, prevLocation })
}

/**
 * Trigger gatsby event on window
 */
exports.onPrefetchPathname = ({ pathname, loadPage }) => {
  triggerEvent(EVENTS.PREFETCH_PATHNAME, { pathname, loadPage })
}

/**
 * Trigger gatsby event on window
 */
exports.onRouteUpdate = ({ location, prevLocation }) => {
  // force scroll unlock
  clearQueueScrollLocks()
  enablePageScroll()

  // trigger events
  triggerEvent(EVENTS.ROUTE_UPDATE, { location, prevLocation })
  callWhenIdle(() => triggerEvent(EVENTS.FORCE_REDETECT))
}

/**
 * Trigger gatsby event on window
 */
exports.onRouteUpdateDelayed = ({ location, action }) => {
  triggerEvent(EVENTS.ROUTE_UPDATE_DELAYED, { location, action })
}

/**
 * Trigger gatsby event on window
 */
exports.onServiceWorkerActive = ({ serviceWorker }) => {
  triggerEvent(EVENTS.SERVICE_WORKER_ACTIVE, { serviceWorker })
}

/**
 * Trigger gatsby event on window
 */
exports.onServiceWorkerInstalled = ({ serviceWorker }) => {
  triggerEvent(EVENTS.SERVICE_WORKER_INSTALLED, { serviceWorker })
}

/**
 * Trigger gatsby event on window
 */
exports.onServiceWorkerUpdateFound = ({ serviceWorker }) => {
  triggerEvent(EVENTS.SERVICE_WORKER_UPDATE_FOUND, { serviceWorker })
}

/**
 * Trigger gatsby event on window
 */
exports.onServiceWorkerUpdateReady = ({ serviceWorker }) => {
  triggerEvent(EVENTS.SERVICE_WORKER_UPDATE_READY, { serviceWorker })
}
