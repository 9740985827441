// config
import { EVENTS, SSR } from "../../client/config"

// utility
import isSSR from "../utility/is-ssr"
import memoize from "../utility/memoize"

/**
 * Calculate values from window across browsers
 */
function getCrossBrowserWindowValues() {
  // return default values for srr
  if (isSSR()) {
    return {
      scroll: {
        top: 0,
        bottom: SSR.WINDOW.HEIGHT,
        maxTop: 0,
        maxBottom: SSR.WINDOW.HEIGHT,
        width: SSR.WINDOW.WIDTH,
        height: SSR.WINDOW.HEIGHT,
      },
      size: {
        width: SSR.WINDOW.WIDTH,
        height: SSR.WINDOW.HEIGHT,
        offsetWidth: SSR.WINDOW.WIDTH,
        offsetHeight: SSR.WINDOW.HEIGHT,
        maxWidth: SSR.WINDOW.WIDTH,
        maxHeight: SSR.WINDOW.HEIGHT,
        resolutionWidth: SSR.WINDOW.WIDTH,
        resolutionHeight: SSR.WINDOW.HEIGHT,
      },
    }
  }

  // build cache and invalidate on window changes
  const cache = {}

  const handleClear = (...cacheKeys) => () => {
    if (cacheKeys.includes("scroll")) delete cache.scroll
    if (cacheKeys.includes("size")) delete cache.size

    console.log("cleared win values cache")
  }

  const eventOpts = {
    capture: true,
    passive: true,
  }

  window.addEventListener(
    EVENTS.ROUTE_UPDATE,
    handleClear("scroll", "size"),
    eventOpts
  )

  window.addEventListener(
    EVENTS.FORCE_REDETECT,
    handleClear("scroll", "size"),
    eventOpts
  )

  window.addEventListener("resize", handleClear("scroll", "size"), eventOpts)

  window.addEventListener("scroll", handleClear("scroll"), eventOpts)

  // cross browser getters
  const win = {
    scroll: {
      /**
       * scroll position at top of window (px)
       */
      top: () => document.documentElement.scrollTop || window.pageYOffset,
      /**
       * scroll position at bottom of window (px)
       */
      bottom: () => win.scroll.top() + win.size.height(),
      /**
       * max possible value of win.scroll.top (px)
       */
      maxTop: () => win.scroll.height() - win.size.height(),
      /**
       * max possible value of win.scroll.bottom (px)
       */
      maxBottom: () => win.scroll.height(),
      /**
       * window width including overflowing content (px)
       */
      width: () => document.documentElement.scrollWidth,
      /**
       * window height including overflowing content (px)
       */
      height: () => document.documentElement.scrollHeight,
    },
    size: {
      /**
       * window width (px)
       * including padding, but not border, scrollbar, or margin
       */
      width: () =>
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth,
      /**
       * window height (px)
       * including padding, but not border, scrollbar, or margin
       */
      height: () =>
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight,
      /**
       * window width (px)
       * including padding, but not margin, border, or scrollbar
       */
      offsetWidth: () => document.documentElement.offsetWidth,
      /**
       * window height (px)
       * including padding, but not margin, border, or scrollbar
       */
      offsetHeight: () => document.documentElement.offsetHeight,
      /**
       * screen width (px)
       */
      maxWidth: () => window.screen.width,
      /**
       * screen height (px)
       */
      maxHeight: () => window.screen.height,
      /**
       * resolution width (px)
       */
      resolutionWidth: () => window.screen.width * window.devicePixelRatio,
      /**
       * resolution height (px)
       */
      resolutionHeight: () => window.screen.height * window.devicePixelRatio,
    },
  }

  // proxy categories
  return new Proxy(cache, {
    get: (cache, category) =>
      // proxy values
      (cache[category] =
        cache[category] ||
        new Proxy(
          {},
          {
            get: (data, key) => {
              // return cached value
              if (typeof data[key] !== "undefined") {
                return data[key]
              }

              // measure value
              if (
                typeof win[category] === "object" &&
                typeof win[category][key] === "function"
              ) {
                return (data[key] = win[category][key]())
              }

              // warn
              console.warn(
                `cross browser window values is not a valid request`,
                category,
                key
              )
            },
          }
        )),
  })
}

export default memoize(getCrossBrowserWindowValues, null, false)
