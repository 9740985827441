/**
 * Try to call a function when the javascript call stack is empty.
 */
function callWhenIdle(func, delay = 0) {
  let caller = setTimeout

  if (typeof requestIdleCallback === "function") {
    caller = requestIdleCallback
  } else if (typeof requestAnimationFrame === "function") {
    caller = requestAnimationFrame
  } else {
    caller = setTimeout
  }

  return setTimeout(() => caller(func), delay)
}

export default callWhenIdle
