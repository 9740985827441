/**
 * Amount of time to delay navigation between pages (ms).
 * Used for easy page transitions.
 */
export const NAVIGATE_DELAY = 0

/**
 * Layout breakpoints
 */
export const BREAKPOINTS = {
  MOBILE: 1023,
}

/**
 * SSR browser fallback values
 */
export const SSR = {
  WINDOW: {
    WIDTH: 1920,
    HEIGHT: 1080,
  },
}

/**
 * Custom Events
 */
export const EVENTS = {
  /**
   * Called when the Gatsby browser runtime first starts
   */
  CLIENT_ENTRY: "gatsbyClientEntry",

  /**
   * Called when the initial (but not subsequent) render of Gatsby App is done
   * on the client.
   */
  INITIAL_CLIENT_RENDER: "gatsbyInitialClientRender",

  /**
   * Called when an internal anchor link is clicked.
   */
  WILL_NAVIGATE: "skeleWillNavigate",

  /**
   * Called when prefetching for a pathname is successful.
   */
  POST_PREFETCH_PATHNAME: "gatsbyPostPrefetchPathname",

  /**
   * Called when changing location is started.
   */
  PRE_ROUTE_UPDATE: "gatsbyPreRouteUpdate",

  /**
   * Called when prefetching for a pathname is triggered.
   */
  PREFETCH_PATHNAME: "gatsbyPrefetchPathname",

  /**
   * Called when the user changes routes.
   */
  ROUTE_UPDATE: "gatsbyRouteUpdate",

  /**
   * Called when changing location is longer than 1 second.
   */
  ROUTE_UPDATE_DELAYED: "gatsbyRouteUpdateDelayed",

  /**
   * Called when a service worker has become active.
   */
  SERVICE_WORKER_ACTIVE: "gatsbyServiceWorkerActive",

  /**
   * Called when a service worker has been installed.
   */
  SERVICE_WORKER_INSTALLED: "gatsbyServiceWorkerInstalled",

  /**
   * Called when a service worker has an update available.
   */
  SERVICE_WORKER_UPDATE_FOUND: "gatsbyServiceWorkerUpdateFound",

  /**
   * Called when a service worker has an update available.
   */
  SERVICE_WORKER_UPDATE_READY: "gatsbyServiceWorkerUpdateReady",

  /**
   * Called when a service worker has been updated in the background and the
   * page is ready to reload to apply changes.
   */
  SERVICE_WORKER_INSTALLED: "gatsbyServiceWorkerInstalled",

  /**
   * Called whenever an async request is made.
   */
  ASYNC_LOAD_START: "skeleAsyncLoadStart",

  /**
   * Called whenever an async request is completed.
   */
  ASYNC_LOAD_END: "skeleLoadEnd",

  /**
   * Called whenever an async request is made.
   */
  ASYNC_LOAD_END_ALL: "skeleLoadEndAll",

  /**
   * Called when page change transition starts
   */
  PAGE_CHANGE_START: "skelePageChangeStart",

  /**
   * Called when page change transition ends
   */
  PAGE_CHANGE_END: "skelePageChangeEnd",

  /**
   * Called whenever functions that detect position/size/etc. need to update.
   */
  FORCE_REDETECT: "skeleForceRedetect",
}
