// lodash
import throttle from "lodash/throttle"

// utility
import isSSR from "../utility/is-ssr"

/**
 * Trigger an event defined in `gatsby-browser.js`
 */
function triggerEvent(eventName, detail) {
  if (!isSSR()) {
    window.dispatchEvent(
      detail ? new CustomEvent(eventName, { detail }) : new Event(eventName)
    )
  }
}

export default triggerEvent
