import cloneDeep from "lodash/clonedeep"

/**
 * Cache a function result
 */
function memoize(func, context = null, cloneResult = true) {
  let cache

  // wrap function with cache setter/getter
  const handler = (...args) => {
    // prefer from cache
    if (typeof cache !== "undefined") {
      // return object references
      if (!cloneResult) {
        return cache
      }

      // return promises with cloned result
      if (cache instanceof Promise) {
        return new Promise(async resolve => resolve(cloneDeep(await cache)))
      }

      // return cloned object
      return cloneDeep(cache)
    }

    // get from func if nothing in cache
    const result = func.call(context, ...args)
    return (cache = result)
  }

  // provide a way to clear the cache
  handler.clear = () => {
    cache = null
  }

  // provide a way to get original func
  handler.unmemoize = () => {
    return func
  }

  return handler
}

export default memoize
